<template>
  <div>
    <link-expired />
  </div>
</template>
<script>
import LinkExpired from '@/components/cleanui/system/Errors/LinkExpired'
export default {
  components: {
    LinkExpired,
  },
}
</script>
